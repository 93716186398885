import React from "react";
import '../../assets/css/pages/coming-soon/comingsoon.css';
import Footer from "../../components/footer";
import Header from "../../components/header";

const ComingSoonPage = () => {
    return (
        <div>

            <section className="coming-soon">
                <div className="container dev-container">
                    <div className="row">
                        <div className="col col-1">
                            <img className="logo" src="/assets/img/coming-soon/logo-white.svg" />
                        </div>
                        <div className="col col-2">
                            <h1>Coming Soon</h1>
                            <p>
                                Our developers are working on this page currently, and it will be published soon. We understand
                                that you may need services related to this page. If so, use this form below to communicate with
                                us. Our team will be in touch with you as soon as possible.
                            </p>
                            <div className="coming-soon-contacts">
                                {/* Email */}
                                <div className="col-contacts">
                                    <i className="fa fa-envelope"></i>
                                    <img className="contact-image" src="/assets/img/home/email.png" alt="" />
                                </div>
                                {/* Phone */}
                                <div className="col-contacts">
                                    <i className="fa fa-phone"></i>
                                    <a href="tel:+250791902159">+250791902159</a>
                                </div>
                            </div>
                            {/* Social Media */}
                            <div className="social-media">
                                {/* Facebook */}
                                <div className="icon">
                                    <a href="https://www.facebook.com/profile.php?id=100086812094253&mibextid=LQQJ4d">
                                        <i class="fa-brands fa-facebook"></i>
                                    </a>
                                </div>
                                {/* Instagram */}
                                <div className="icon">
                                    <a href="https://www.instagram.com/csr.rw/">
                                        <i class="fa-brands fa-square-instagram"></i>
                                    </a>
                                </div>
                                {/* YouTube */}
                                <div className="icon">
                                    <a href="https://www.youtube.com/@csrlimitedkigalicsr">
                                        <i class="fa-brands fa-youtube"></i>
                                    </a>
                                </div>
                                {/* Twitter */}
                                <div className="icon">
                                    <a href="https://twitter.com/csr_rw">
                                        <i class="fa-brands fa-twitter"></i>
                                    </a>
                                </div>
                                {/* LinkedIn */}
                                <div className="icon">
                                    <a href="https://www.linkedin.com/company/comprehensive-staffing-resources-ltd/">
                                        <i class="fa-brands fa-linkedin"></i>
                                    </a>
                                </div>
                            </div>
                            <a href="/">
                                <button>Go back to home page</button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default ComingSoonPage;
